module.exports = [{
      plugin: require('/Users/devall/projects/side/hobby/gatsby-london/node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"usePrefix":["embed","video","oembed"],"settings":{"Instagram":{"hidecaption":true}}},
    },{
      plugin: require('/Users/devall/projects/side/hobby/gatsby-london/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/devall/projects/side/hobby/gatsby-london/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/devall/projects/side/hobby/gatsby-london/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
